@use "../abstracts/variables";
@use "../abstracts/type-styles";
@use "../utilities/mixins";

.projects__title {
    padding: var(--s-l);
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    @extend %title-one;
}

.project__details {
    padding: var(--s-l);
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.project__overview {
    flex-basis: 100%;
    width: 100%;

    @include mixins.respond-above(variables.$break-s) {
        flex-basis: calc(75% - 1rem);
        width: calc(75% - 1rem);
        margin-left: 1rem;
    }

    @include mixins.respond-above(variables.$break-l) {
        flex-basis: calc(50% - 1rem);
        width: calc(50% - 1rem);
        margin-left: 1rem;
    }
}

.project__title {
    flex-basis: 100%;
    @extend %title-one;
    margin-top: 0;
}

.project__description {
    max-width: 30em;
    a {
        display: inline-block;
        transform: translateY(0);
        transition: all 0.2s ease;
        text-decoration-thickness: 2px;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                transform: translateY(-2px);
            }
        }
    }
}

.project__links {
    display: flex;
    flex-wrap: wrap;
}

.project__link {
    a {
        margin: 0;
        display: inline;
    }
}

.project__meta {
    flex-basis: 100%;
    width: 100%;
    margin-top: 0;
    columns: 2;

    @include mixins.respond-above(variables.$break-s) {
        flex-basis: calc(25% - 1rem);
        width: calc(25% - 1rem);
        margin-right: 1rem;
        columns: 1;
    }

    @include mixins.respond-above(variables.$break-l) {
        flex-basis: calc(50% - 1rem);
        width: calc(50% - 1rem);
        margin-right: 1rem;
        columns: 2;
    }
}

.project__meta-detail {
    break-inside: avoid;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--s-s);
}

.project__meta-title {
    margin: 0;
    margin-bottom: var(--s-xs);
    padding: 0;
    font-weight: bold;
    flex-basis: 100%;
    @extend %info-title;
}

.project__meta-subtitle {
    @extend %info-subtitle;
}

.project__meta-body {
    margin: 0;
    margin-bottom: var(--s-xs);
    padding: 0;
    flex-basis: 100%;
    @extend %info-description;
}

// .project__meta-category {
//     margin: 0;
//     padding-right: var(--s-xs);
//     @extend %body;
// }

.project__gallery {
    margin: var(--s-l);
    // margin: 0;
    // display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-basis: 100%;
    box-sizing: border-box;
    position: relative;
}

.project__spacer {
    width: 2rem;
}

.project__sizer {
    flex-basis: calc(50% - 1rem);
    width: calc(50% - 1rem);

    @include mixins.respond-above(variables.$break-m) {
        flex-basis: calc(25% - 1.5rem);
        width: calc(25% - 1.5rem);
    }
}

.project__image-container {
    color: var(--c-text);
    margin: 0 0 var(--s-l) 0;
    position: relative;
    box-sizing: border-box;
}

.project__image--large {
    flex-basis: 100%;
    width: 100%;

    @include mixins.respond-above(variables.$break-m) {
        flex-basis: calc(50% - 1rem);
        width: calc(50% - 1rem);
    }
}

.project__image--small {
    flex-basis: calc(50% - 1rem);
    width: calc(50% - 1rem);

    @include mixins.respond-above(variables.$break-m) {
        flex-basis: calc(25% - 1.5rem);
        width: calc(25% - 1.5rem);
    }
}

.project__image {
    width: 100%;
    filter: drop-shadow(10px 10px 15px var(--c-d));
}

.project__caption {
}

.project__video-container {
    text-decoration: none;
    color: var(--c-text);
    margin-bottom: var(--s-l);
}

.project__video--large {
    flex-basis: 100%;
    width: 100%;
}

.project__video--small {
    flex-basis: 100%;
    width: 100%;

    @include mixins.respond-above(variables.$break-m) {
        // flex-basis: 100%;
        // width: 100%;
        flex-basis: calc(50% - 1rem);
        width: calc(50% - 1rem);
        margin-right: 2rem;
    }
}

.project__video {
    overflow: hidden;
    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    position: relative;
    filter: drop-shadow(10px 10px 15px var(--c-d));

    iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.project__navigation {
    flex-basis: 100%;
    padding: var(--s-l);
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.project__navigation--right {
    justify-content: flex-end;
}

.project__navigation-title {
    @extend %title-two;
    flex-basis: 100%;
    margin-bottom: var(--s-s);
    // text-align: center;

    @include mixins.respond-above(variables.$break-m) {
        flex-basis: auto;
        margin-bottom: 0;
    }
}

.project__navigation-title--left {
    display: inline-block;
    transform: translateX(0);
    transition: all 0.2s ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            transform: translateX(-10px);
        }
    }
}

.project__navigation-title--right {
    text-align: right;
    display: inline-block;
    transform: translateX(0);
    transition: all 0.2s ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            transform: translateX(10px);
        }
    }
}
