@use "../abstracts/variables";
@use "../abstracts/type-styles";
@use "../utilities/mixins";

.header {
    display: flex;
    justify-content: space-between;
    background-color: var(--c-background);
    filter: drop-shadow(0 10px 10px var(--c-a));
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 30;
}

.main {
    // padding: var(--s-l);
    display: flex;
    flex-wrap: wrap;
    background-color: var(--c-background);
    filter: drop-shadow(0 10px 10px var(--c-c));
    position: relative;
    z-index: 20;
    margin-top: 5rem;
}

.footer {
    padding: var(--s-l);
    display: flex;
    justify-content: space-between;
    @extend %footer;
    position: relative;
    z-index: 10;
}

.title {
    flex-basis: 100%;
    padding: var(--s-l);
    margin: 0;
    @extend %title-one;
}

.error {
    flex-basis: 100%;
    padding: var(--s-l);
    margin: 0;

    font-display: swap;
    font-weight: normal;
    font-style: normal;
    line-height: var(--l-tight);
    -moz-font-feature-settings: "ss01"; /* low-level (old Firefox)      */
    -webkit-font-feature-settings: "ss01"; /* low-level (old Webkit)       */
    font-feature-settings: "ss01" on; /* low-level (all new browsers)   */
    font-size: 8vw;
    text-align: justify;

    @include mixins.respond-above(variables.$break-s) {
        font-size: 8.3vw;
    }

    @include mixins.respond-above(variables.$break-m) {
        font-size: 8.7vw;
    }

    @include mixins.respond-above(variables.$break-l) {
        font-size: 9.2vw;
    }
}

.error--left {
    font-family: "Francis Gradient Left", sans-serif;
}
.error--right {
    font-family: "Francis Gradient Right", sans-serif;
}
