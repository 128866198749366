@use "../abstracts/type-styles";
@use "../abstracts/variables";
@use "../utilities/mixins";

.logo__container {
    flex-basis: 50%;
    padding: var(--s-m) var(--s-l);
    position: relative;
    overflow: hidden;
    z-index: 31;
}

.logo__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    background: linear-gradient(to right, var(--c-a), rgba(255, 255, 255, 0));
    transition: all 0.2s ease;
    z-index: 35;
    pointer-events: none;
}

.logo {
    color: var(--c-text);
    @extend %logo;
    z-index: 36;
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: all 0.2s ease;
    transform: translateY(0);

    &:hover {
        transform: translateY(-5px);
    }

    &.active {
        text-decoration: none;
        cursor: default;

        &:hover {
            transform: translateY(0px);
        }
    }
}

.menu {
    flex-basis: auto;
    display: flex;
    justify-content: flex-end;
    background: var(--c-background);
    position: relative;
    overflow: hidden;
    z-index: 32;
    padding: var(--s-m) var(--s-l);

    @include mixins.respond-above(variables.$break-s) {
        flex-basis: 50%;
    }
}

.menu__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: -100%;
    background: linear-gradient(to left, var(--c-a), rgba(255, 255, 255, 0));
    transition: all 0.2s ease;
    z-index: 33;
    pointer-events: none;
}

.menu__items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
}

.menu__item {
    margin: 0 0 0 var(--s-s);
    padding: 0;
    @extend %nav;
    display: flex;
    justify-content: flex-end;
    color: var(--c-text);
    text-align: right;
    z-index: 34;
    cursor: pointer;
    transition: all 0.2s ease;
    transform: translateY(0);

    &:hover {
        transform: translateY(-5px);
    }

    &.active {
        text-decoration: none;
        cursor: default;

        &:hover {
            transform: translateY(0px);
        }
    }
}
