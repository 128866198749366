@use "../utilities/mixins";

/* --------------------------------------------------------
    BREAKPOINTS
    -------------------------------------------------------- */
$break-s: 512px;
$break-m: 768px;
$break-l: 1024px;
$break-xl: 1400px;

$default-grid-columns: 12;
$default-grid-gutter: 2%;
$flexbox-grid-mixins-grid-type: margin-offset;

:root {
    /* --------------------------------------------------------
    COLOR VARIABLE LIST
    -------------------------------------------------------- */
    --c-text: #1b1217;
    --c-background: #f7f6f2;
    --c-a: #cae6ff;
    --c-a-dark: #a0cef6;
    --c-b: #ffd8e1;
    --c-b-dark: #f0bfcb;
    --c-c: #e0f1cb;
    --c-c-dark: #c6e69f;
    --c-d: #ebd1fb;
    --c-d-dark: #dfbaf6;

    /* --------------------------------------------------------
    SPACING VARIABLE LIST
    -------------------------------------------------------- */
    --s-xxs: 0.25rem;
    --s-xs: 0.5rem;
    --s-s: 0.9rem;
    --s-m: 1.5rem;
    --s-l: 2rem;
    --s-xl: 3rem;
    --s-xxl: 5rem;
    --s-xxxl: 10rem;
    --s-xxxxl: 10rem;
    @include mixins.respond-above($break-s) {
        --s-xxs: 0.25rem;
        --s-xs: 0.5rem;
        --s-s: 1rem;
        --s-m: 1.5rem;
        --s-l: 2rem;
        --s-xl: 3rem;
        --s-xxl: 5rem;
        --s-xxxl: 10rem;
        --s-xxxxl: 10rem;
    }
    @include mixins.respond-above($break-m) {
        --s-xxs: 0.25rem;
        --s-xs: 0.5rem;
        --s-s: 1rem;
        --s-m: 1.5rem;
        --s-l: 2rem;
        --s-xl: 3rem;
        --s-xxl: 5rem;
        --s-xxxl: 10rem;
        --s-xxxxl: 10rem;
    }
    @include mixins.respond-above($break-l) {
        --s-xxs: 0.25rem;
        --s-xs: 0.5rem;
        --s-s: 1rem;
        --s-m: 1.5rem;
        --s-l: 2rem;
        --s-xl: 3rem;
        --s-xxl: 5rem;
        --s-xxxl: 10rem;
        --s-xxxxl: 10rem;
    }

    /* --------------------------------------------------------
    GRID VALUES
    -------------------------------------------------------- */
    --grid-col-num: 12;
    --grid-col-gutter: 3rem;

    /* --------------------------------------------------------
    LEADING VARIABLE LIST
    -------------------------------------------------------- */
    --l-tight: 1;
    --l-regular: 1.3;
    --l-loose: 1.8;

    /* --------------------------------------------------------
    TYPE SIZE VARIABLE LIST
    -------------------------------------------------------- */
    --t-xs: 14px;
    --t-s: 16px;
    --t-m: 18px;
    --t-l: 21px;
    --t-xl: 24px;
    --t-xxl: 30px;
    --t-xxxl: 36px;
    @include mixins.respond-above($break-s) {
        --t-xs: 14px;
        --t-s: 16px;
        --t-m: 18px;
        --t-l: 18px;
        --t-xl: 24px;
        --t-xxl: 30px;
        --t-xxxl: 50px;
    }
    @include mixins.respond-above($break-m) {
        --t-xs: 14px;
        --t-s: 16px;
        --t-m: 21px;
        --t-l: 24px;
        --t-xl: 28px;
        --t-xxl: 36px;
        --t-xxxl: 60px;
    }
    @include mixins.respond-above($break-l) {
        --t-xs: 14px;
        --t-s: 16px;
        --t-m: 21px;
        --t-l: 24px;
        --t-xl: 30px;
        --t-xxl: 40px;
        --t-xxxl: 70px;
    }

    /* --------------------------------------------------------
    TYPE STYLE VARIABLE LIST
    -------------------------------------------------------- */
    --t-left: "Francis Gradient Left";
    --t-right: "Francis Gradient Right";
    --t-outside: "Francis Gradient Outside";
    --t-inside: "Francis Gradient Inside";
}
