@use "../abstracts/type-styles";
@use "../abstracts/variables";

body {
    background-color: var(--c-background);
    color: var(--c-text);

    @extend %body;
}

p {
    margin: 0;

    & + p {
        margin-top: var(--s-xs);
    }
}

a {
    color: var(--c-text);
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
p,
figure,
blockquote,
cite {
    // margin: 0;
}

img {
    // width: 100%;
}

// a {
//     color: var(--c-text);
// }
