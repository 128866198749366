@use "../abstracts/variables";
@use "../abstracts/type-styles";
@use "../utilities/mixins";

.footer__item {
    margin: 0;
    padding: 0;

    a {
        margin: 0;
        padding: 0;
    }
}
