@use "../abstracts/variables";
@use "../abstracts/type-styles";
@use "../utilities/mixins";

.bio {
    padding: var(--s-l);
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: var(--c-background);
    filter: drop-shadow(0 15px 10px var(--c-d));
    flex-basis: 100%;
}

.bio__title {
    flex-basis: 100%;
    @extend %title-one;
    margin-top: 0;
    margin-bottom: var(--s-l);
}

.bio__description {
    flex-basis: 100%;
    width: 100%;

    @include mixins.respond-above(variables.$break-m) {
        flex-basis: calc(75% - 1rem);
        width: calc(75% - 1rem);
        margin-right: 1rem;
    }

    @include mixins.respond-above(variables.$break-l) {
        flex-basis: calc(50% - 1rem);
        width: calc(50% - 1rem);
        margin-right: 1rem;
    }

    p {
        padding: 0;
        margin: 0;
        @extend %body;
        max-width: 30em;
    }

    a {
        display: inline-block;
        transform: translateY(0);
        transition: all 0.2s ease;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                transform: translateY(-2px);
            }
        }
    }
}

.bio__headshot {
    flex-basis: 100%;
    width: 100%;
    margin-top: var(--s-m);
    filter: drop-shadow(10px 10px 10px var(--c-b));

    @include mixins.respond-above(variables.$break-s) {
        flex-basis: 50%;
        width: 50%;
    }

    @include mixins.respond-above(variables.$break-m) {
        flex-basis: 20%;
        width: 20%;
        margin-left: 1rem;
        margin-top: 0;
    }

    @include mixins.respond-above(variables.$break-l) {
        flex-basis: 20%;
        width: 20%;
        margin-left: 1rem;
    }
}

.values {
    padding: var(--s-l);
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    background-color: var(--c-background);
}

.values__title {
    flex-basis: 100%;
    @extend %title-one;
    margin-top: 0;
    margin-bottom: var(--s-l);
}

.value {
    color: var(--c-text);
    flex-basis: 100%;
    width: 100%;
    margin-bottom: var(--s-l);

    @include mixins.respond-above(variables.$break-m) {
        flex-basis: calc(50% - 1rem);
        width: calc(50% - 1rem);

        &:nth-child(odd) {
            margin-left: 1rem;
        }
        &:nth-child(even) {
            margin-right: 1rem;
        }
    }
}

.value__title {
    @extend %title-three;
    margin-top: 0;
    margin-bottom: var(--s-xs);
    padding: 0;
}

.value__description {
    p {
        @extend %body;
        margin: 0;
        max-width: 30em;
    }
}
