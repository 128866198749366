@use "../abstracts/variables";
@use "../abstracts/type-styles";
@use "../utilities/mixins";

.library {
    padding: var(--s-l);
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.library__title {
    flex-basis: 100%;
    @extend %title-one;
}

.library__filters {
    display: flex;
    flex-wrap: wrap;
}

.library__filter {
    font-family: "Francis Gradient Right", sans-serif;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-size: var(--t-xl);
    line-height: var(--l-tight);
    font-feature-settings: "ss01" on;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    margin-right: 1rem;

    &.active {
        text-decoration: underline;
    }

    &:hover {
        text-decoration: underline;
    }
}

.library__selects {
    --row-gap: 2rem;
    --row-gap--label: 0rem;
    --column-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: calc(var(--row-gap) + var(--row-gap--label));
    column-gap: var(--column-gap);

    @include mixins.respond-above(variables.$break-m) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mixins.respond-above(variables.$break-l) {
        grid-template-columns: repeat(5, 1fr);
    }

    @include mixins.respond-above(variables.$break-xl) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.library__bookshelf {
    --row-gap: 2rem;
    --row-gap--label: 2rem;
    --column-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: calc(var(--row-gap) + var(--row-gap--label));
    column-gap: var(--column-gap);
    margin-top: 2rem;

    @include mixins.respond-above(variables.$break-m) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mixins.respond-above(variables.$break-l) {
        grid-template-columns: repeat(5, 1fr);
    }

    @include mixins.respond-above(variables.$break-xl) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.bookshelf__collection {
    display: contents;
}

.collection__items {
    display: contents;
}

.library-item:not([hidden]):first-child::before,
.library-item[hidden] + .library-item:not([hidden])::before {
    content: var(--label);
    display: block;
    margin-top: calc(var(--row-gap--label) * -1);
    height: 2rem;
    font-family: "Francis Gradient Right", sans-serif;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-size: var(--t-s);
    line-height: var(--l-tight);
    font-feature-settings: "ss01" on;
}

.library-item:not([hidden]):first-child ~ .library-item::before,
.library-item[hidden] + .library-item:not([hidden]) ~ .library-item::before {
    content: revert;
}

.library-item[hidden] {
    display: none;
}

.library-item__date {
    // margin-bottom: -1rem;
    font-family: "Francis Gradient Right", sans-serif;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-size: var(--t-s);
    line-height: var(--l-tight);
    font-feature-settings: "ss01" on;
    height: 1rem;

    & + .library-item__link {
        // padding-top: 1rem;
        margin-top: 0;
    }
}

.library-item {
    text-decoration: none;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            .library-item__cover {
                transform: translateY(-10px);

                &.library-item__podcasts {
                    filter: drop-shadow(15px 15px 15px var(--c-a-dark));
                }

                &.library-item__books {
                    filter: drop-shadow(15px 15px 15px var(--c-b-dark));
                }

                &.library-item__music {
                    filter: drop-shadow(15px 15px 15px var(--c-c-dark));
                }

                &.library-item__articles {
                    filter: drop-shadow(15px 15px 15px var(--c-d-dark));
                }
            }
        }
    }
}

.library-item__select {
    margin-top: 0;
}

.library-item__cover {
    width: 100%;
    filter: drop-shadow(5px 5px 5px var(--c-b));
    transform: translateY(0);
    transition: all 0.2s ease;
    margin-bottom: 0.5rem;

    &.library-item__podcasts {
        filter: drop-shadow(5px 5px 5px var(--c-a));
    }

    &.library-item__books {
        filter: drop-shadow(5px 5px 5px var(--c-b));
    }

    &.library-item__music {
        filter: drop-shadow(5px 5px 5px var(--c-c));
    }

    &.library-item__articles {
        filter: drop-shadow(5px 5px 5px var(--c-d));
    }
}

.library-item__title {
    @extend %card-title--1;
    margin: 0;
}

.library-item__subtitle {
    @extend %card-title--2;
    margin: 0;
}

.library-item__author {
    @extend %card-title--3;
    margin: 0;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
