@use "../abstracts/variables";
@use "../abstracts/type-styles";
@use "../utilities/mixins";

.intro__container {
    flex-basis: 100%;
    padding: var(--s-l);
}

.intro {
    flex-basis: 100%;
    @extend %callout;
    max-width: 40em;

    p {
        padding: 0;
        margin: 0;
        max-width: 30em;
    }

    a {
        display: inline-block;
        transform: translateY(0);
        transition: all 0.2s ease;
        text-decoration-thickness: 2px;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                transform: translateY(-4px);
            }
        }
    }
}

.projects {
    display: flex;
    flex-wrap: wrap;
    padding: var(--s-l);
}

.project__link {
    flex-basis: 100%;
    width: 100%;
    text-decoration: none;
    color: var(--c-text);
    margin-bottom: var(--s-l);

    @include mixins.respond-above(variables.$break-m) {
        flex-basis: calc(50% - 1rem);
        width: calc(50% - 1rem);
        &:nth-child(even) {
            margin-left: 1rem;
        }
        &:nth-child(odd) {
            margin-right: 1rem;
        }
    }
}

.project__card {
    margin: 0;
    padding: 0;
}

.project__cover {
    width: 100%;
    filter: drop-shadow(10px 10px 15px var(--c-b));
    transform: translateY(0);
    transition: all 0.2s ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            filter: drop-shadow(20px 20px 15px var(--c-b-dark));
            transform: translateY(-10px);
        }
    }
}

.project__card-title {
    @extend %title-two;
}

.project__categories {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    @extend %body;
}

.project__category {
    margin-right: var(--s-xs);
    text-decoration: none;
}
