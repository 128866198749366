@use "../utilities/mixins";
@use "../abstracts/variables";

/*
Type styles
This uses the type size mixin and variables defined in _type-sizes.scss
*/

%title-one {
    font-family: "Francis Gradient Left", sans-serif;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-size: var(--t-xxxl);
    line-height: var(--l-tight);
    -moz-font-feature-settings: "ss01"; /* low-level (old Firefox)      */
    -webkit-font-feature-settings: "ss01"; /* low-level (old Webkit)       */
    font-feature-settings: "ss01" on; /* low-level (all new browsers)   */
}

%title-two {
    font-family: "Francis Gradient Left", sans-serif;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-size: var(--t-xxl);
    line-height: var(--l-regular);
    -moz-font-feature-settings: "ss01"; /* low-level (old Firefox)      */
    -webkit-font-feature-settings: "ss01"; /* low-level (old Webkit)       */
    font-feature-settings: "ss01" on; /* low-level (all new browsers)   */
}

%title-three {
    font-family: "Francis Gradient Left", sans-serif;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-size: var(--t-xl);
    line-height: var(--l-regular);
    -moz-font-feature-settings: "ss01"; /* low-level (old Firefox)      */
    -webkit-font-feature-settings: "ss01"; /* low-level (old Webkit)       */
    font-feature-settings: "ss01" on; /* low-level (all new browsers)   */
}

%callout {
    font-family: "Archivo", sans-serif;
    font-display: swap;
    font-weight: 200;
    font-style: normal;
    font-size: var(--t-xl);
    line-height: var(--l-regular);
}

%body {
    font-family: "Archivo", sans-serif;
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    font-size: var(--t-m);
    line-height: var(--l-regular);
}

%info-title {
    font-family: "Francis Gradient Outside", sans-serif;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-size: var(--t-m);
    line-height: var(--l-regular);
    -moz-font-feature-settings: "ss01"; /* low-level (old Firefox)      */
    -webkit-font-feature-settings: "ss01"; /* low-level (old Webkit)       */
    font-feature-settings: "ss01" on; /* low-level (all new browsers)   */
}

%info-subtitle {
    font-family: "Archivo", sans-serif;
    font-display: swap;
    font-weight: 400;
    font-style: italic;
    font-size: var(--t-s);
    line-height: var(--l-regular);
}

%info-description {
    font-family: "Archivo", sans-serif;
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    font-size: var(--t-s);
    line-height: var(--l-regular);
}

%card-title--1 {
    font-family: "Archivo", sans-serif;
    font-display: swap;
    font-weight: bold;
    font-style: normal;
    font-size: var(--t-s);
    line-height: var(--l-regular);
}

%card-title--2 {
    font-family: "Archivo", sans-serif;
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    font-size: var(--t-s);
    line-height: var(--l-regular);
}

%card-title--3 {
    font-family: "Archivo", sans-serif;
    font-display: swap;
    font-weight: 400;
    font-style: italic;
    font-size: var(--t-s);
    line-height: var(--l-regular);
}

%logo {
    font-family: "Francis Gradient Left", sans-serif;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-size: var(--t-m);
    line-height: var(--l-tight);
    -moz-font-feature-settings: "ss01"; /* low-level (old Firefox)      */
    -webkit-font-feature-settings: "ss01"; /* low-level (old Webkit)       */
    font-feature-settings: "ss01" on; /* low-level (all new browsers)   */
}

%nav {
    font-family: "Francis Gradient Right", sans-serif;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-size: var(--t-m);
    line-height: var(--l-tight);
    -moz-font-feature-settings: "ss01"; /* low-level (old Firefox)      */
    -webkit-font-feature-settings: "ss01"; /* low-level (old Webkit)       */
    font-feature-settings: "ss01" on; /* low-level (all new browsers)   */
}

%footer {
    font-family: "Archivo", sans-serif;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-size: var(--t-s);
    line-height: var(--l-regular);
}

%inline-link {
    color: var(--c-text);
    transition: color 0.2s ease;

    &:hover {
        color: var(--c-background);
    }
}

%block-link {
    color: var(--c-text);
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
        color: var(--c-background);
    }
}

%antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

/*
Font declarations
*/

// @font-face {
//     font-family: "Benton Sans";
//     src: url("../fonts/Benton Sans Book FF&P.otf") format("opentype");
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: "Benton Sans";
//     src: url("../fonts/Benton Sans Book Italic FF&P.otf") format("opentype");
//     font-weight: 400;
//     font-style: italic;
// }

// @font-face {
//     font-family: "Benton Sans";
//     src: url("../fonts/Benton Sans Medium.otf") format("opentype");
//     font-weight: 600;
//     font-style: normal;
// }

// @font-face {
//     font-family: "Benton Sans";
//     src: url("../fonts/Benton Sans Medium Italic.otf") format("opentype");
//     font-weight: 600;
//     font-style: italic;
// }

// @font-face {
//     font-family: "Benton Sans Compressed";
//     src: url("../fonts/Benton Sans Compressed Book FF&P.otf") format("opentype");
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: "Benton Sans Compressed";
//     src: url("../fonts/Benton Sans Compressed Book Italic FF&P.otf") format("opentype");
//     font-weight: 400;
//     font-style: italic;
// }

// @font-face {
//     font-family: "Benton Sans Compressed";
//     src: url("../fonts/Benton Sans Compressed Medium FF&P.otf") format("opentype");
//     font-weight: 600;
//     font-style: normal;
// }

// @font-face {
//     font-family: "Benton Sans Compressed";
//     src: url("../fonts/Benton Sans Compressed Medium Italic FF&P.otf") format("opentype");
//     font-weight: 600;
//     font-style: italic;
// }
